<template>
    <div style="height: 85vh;">
        <div class="row">
            <header-box :title="`${$t('시스템 관리')} > ${$t('코드 관리')} > ${$t('공통 코드 관리 V2.1')}`" />
        </div>
        <div class="row flex-fill" style="height:100%; flex-wrap:nowrap;">
            <!-- Left Area -->
            <div id="list-1" class="col-md-8"
                style="min-width: 600px; background-color: #d3d6db; height: 100%; padding: 15px !important; border: solid black 1px;">
                <div class="row" style="width:100%; height:100%; ">
                    <!-- Header Area -->
                    <div class="row" style="height:30px; width:100%;">
                        <!-- Title Content -->
                        <div class="col d-flex justify-content-start align-items-center">
                            <h4>공통 코드 목록</h4>
                        </div>

                        <!-- Control Buttons -->
                        <div class="col d-flex justify-content-end  align-item-center">
                            <button type="button" class="btn btn-sm btn-danger mr-1" @click="deleteData()">
                                <trans>삭제</trans>
                            </button>
                            <button type="button" class="btn btn-sm btn-light mr-1" @click="getDataList()">
                                <trans>갱신</trans>
                            </button>
                            <button type="button" class="btn btn-sm btn-light mr-1" @click="setBulkEnabled(true)">
                                <trans>사용</trans>
                            </button>
                            <button type="button" class="btn btn-sm btn-light mr-1" @click="setBulkEnabled(false)">
                                <trans>미사용</trans>
                            </button>
                            <button type="button" class="btn btn-sm btn-warning" @click="showAddSysType()">
                                <trans>추가</trans>
                            </button>
                        </div>
                    </div>

                    <!-- Table List -->
                    <div class="row" style="height:calc(100% - 30px); width:100%;">
                        <div ref="parentDiv" class="col" style="height:100%">
                            <vue-good-table ref="listTable" :id="'listTable'" style="margin-top: 0.5rem;"
                                compactMode :columns="columns" :rows="rows" :fixed-header="true"
                                :max-height="maxTableHeight"
                                :select-options="{ enabled: true, disableSelectInfo: true, selectOnCheckboxOnly: true }"
                                :group-options="{ enabled: true, collapsable: true }" :row-style-class="rowStyleClassFn"
                                @on-selected-rows-change="onSelectedRowChange" @on-cell-click="getData">
                                <div slot="emptystate" style="text-align: center;"> {{ $t('표시할 데이터가 없습니다.') }} </div>
                                <template slot="table-row" slot-scope="props">
                                    <template v-if="props.column.field == 'rowTitle'">
                                        <p class="mb-1" style="color: #000; font-weight: 800;">{{ props.row.codeName ??
                                            "-" }} </p>
                                        <p class="m-0" style="color: #777;">{{ props.row.commonCode ?? "-" }}</p>
                                    </template>
                                    <template v-else-if="props.column.field == 'codeCategory'">
                                        {{ props.row.codeCategory ? props.row.codeCategory : "-" }}
                                    </template>
                                    <template v-else-if="props.column.field == 'desc'">
                                        {{ props.row.desc ? props.row.desc : "-" }}
                                    </template>
                                    <template v-else-if="props.column.field == 'oprtnCodeYn'">
                                        <div class="custom-control custom-switch custom-switch-md">
                                            <input v-model="props.row.oprnCodeYnToggle" type="checkbox"
                                                class="custom-control-input"
                                                :id="`oprtnCodeYn-switch-${props.row.commonCode}`"
                                                @change="setOprtnCodeYn(props.row.oprnCodeYnToggle, props.row.commonCode)">
                                            <label class="custom-control-label"
                                                :for="`oprtnCodeYn-switch-${props.row.commonCode}`"></label>
                                        </div>
                                    </template>
                                    <template v-else-if="props.column.field == 'enabled'">
                                        <div class="custom-control custom-switch custom-switch-md">
                                            <input v-model="props.row.enabledToggle" type="checkbox"
                                                class="custom-control-input"
                                                :id="`state-switch-${props.row.commonCode}`"
                                                @change="setEnabled(props.row.enabledToggle, props.row.commonCode)">
                                            <label class="custom-control-label"
                                                :for="`state-switch-${props.row.commonCode}`"></label>
                                        </div>
                                    </template>
                                    <template v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </template>
                                </template>
                            </vue-good-table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Right Area -->
            <!-- Option1 : 공통코드 추가 -->
            <div v-if="!showDetail" id="list-2" class="d-none d-lg-block"
                style="flex: 1; background-color: #d3d6db; height: 100%; padding: 15px !important; border: solid black 1px">
                <div class="col-md-12" style="background-color: white; border-radius: 10px; height: 100%;">
                    <div style="height: 100%;">
                        <div v-if="!showAdd" class="d-flex justify-content-center align-items-center flex-column"
                            style="height: 100%;">
                            <span style="padding: 1rem; color: #444; font-size: 48px;">
                                <i class="fas fa-external-link-alt"></i>
                            </span>
                            <span style="font-size: 15px; font-weight: bold;">공통 코드를 선택하세요.</span>
                        </div>
                        <div v-else style="padding: 1rem; height: 100%;">
                            <div class="col-md-6 p-0">
                                <span style="font-size: 0.8rem; font-weight: 600;">공통 코드 추가</span>
                            </div>
                            <div class="row justify-content-center align-items-center" style="height: 100%;">
                                <div class="col-md-12">
                                    <form>
                                        <div class="form-group col-md-12 p-0 mb-4">
                                            <label for="commonCode">공통 코드(*)</label>
                                            <input v-model="commonCode.commonCode" type="text" class="form-control"
                                                id="" placeholder="공통 코드">
                                        </div>
                                        <div class="form-group col-md-12 p-0 mb-4">
                                            <label for="codeName">코드명(*)</label>
                                            <input v-model="commonCode.codeName" type="text" class="form-control" id=""
                                                placeholder="코드명">
                                        </div>
                                        <div class="form-group col-md-12 p-0 mb-4">
                                            <label for="enabled">사용여부</label>
                                            <select v-model="commonCode.enabled" id="enabled" class="form-control">
                                                <option value="">선택</option>
                                                <option value="Y">사용</option>
                                                <option value="N">미사용</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-md-12 p-0 mb-5">
                                            <label for="desc">설명</label>
                                            <textarea v-model="commonCode.desc" class="form-control" id="desc"
                                                style="height: 100px; resize: none; overflow-y: auto;"></textarea>
                                        </div>
                                        <div class="row d-flex justify-content-center" style="margin-top: 2rem;">
                                            <button type="button" class="btn btn-primary btn-lg mr-5" v-if="editMode"
                                                @click="saveSystemType()">저장</button>
                                            <button type="button" class="btn btn-secondary btn-lg"
                                                @click="hideSysDetail()">닫기</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Option2 : 공통코드 편집 -->
            <div v-else id="list-2" class="d-none d-lg-block"
                style="flex: 1; background-color: #d3d6db; height: 100%; padding: 15px !important;">
                <div class="col-md-12" style="background-color: white; border-radius: 10px; height: 100%;">
                    <div style="height: 100%;">
                        <div class="row" style="padding: 1rem; height: 10%;">
                            <div class="col-md-6 p-0">
                                <span v-if="!editMode" style="font-size: 0.8rem; font-weight: 600;">공통 코드 조회</span>
                                <span v-else style="font-size: 0.8rem; font-weight: 600;">공통 코드 편집</span>
                            </div>
                            <div
                                class="custom-control custom-switch custom-switch-md col-md-6 d-flex justify-content-end">
                                <input v-model="editMode" type="checkbox" class="custom-control-input"
                                    :id="`edit-switch`">
                                <label class="custom-control-label" :for="`edit-switch`"></label>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center" style="padding: 1rem; height: 80%;">
                            <div class="col-md-12 p-0" style="overflow-y: auto; overflow-x: hidden; height: 100%">
                                <form>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="commonCode">공통 코드(*)</label>
                                        <input v-model="detailInfo.commonCode" type="text" class="form-control" id=""
                                            placeholder="공통 코드" disabled>
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="codeName">코드명(*)</label>
                                        <input v-model="detailInfo.codeName" type="text" class="form-control" id=""
                                            placeholder="코드명" :disabled="!editMode">
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="enabled">현장 운영 코드 Y/N</label>
                                        <select v-model="detailInfo.oprtnCodeYn" id="oprtnCodeYn" class="form-control"
                                            :disabled="!editMode">
                                            <option value="">선택</option>
                                            <option value="Y">사용</option>
                                            <option value="N">미사용</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="enabled">사용여부</label>
                                        <select v-model="detailInfo.enabled" id="enabled" class="form-control"
                                            :disabled="!editMode">
                                            <option value="">선택</option>
                                            <option value="Y">사용</option>
                                            <option value="N">미사용</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="etcInfo1">추가 필드1</label>
                                        <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id=""
                                            placeholder="추가 필드1" :disabled="!editMode">
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="etcInfo2">추가 필드2</label>
                                        <input v-model="detailInfo.etcInfo2" type="text" class="form-control" id=""
                                            placeholder="추가 필드2" :disabled="!editMode">
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="etcInfo3">추가 필드3</label>
                                        <input v-model="detailInfo.etcInfo1" type="text" class="form-control" id=""
                                            placeholder="추가 필드3" :disabled="!editMode">
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="etcInfo1">추가 필드4</label>
                                        <input v-model="detailInfo.etcInfo4" type="text" class="form-control" id=""
                                            placeholder="추가 필드4" :disabled="!editMode">
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-4">
                                        <label for="etcInfo1">추가 필드5</label>
                                        <input v-model="detailInfo.etcInfo5" type="text" class="form-control" id=""
                                            placeholder="추가 필드5" :disabled="!editMode">
                                    </div>
                                    <div class="form-group col-md-12 p-0 mb-5">
                                        <label for="desc">설명</label>
                                        <textarea v-model="detailInfo.desc" class="form-control" id="desc"
                                            style="height: 100px; resize: none; overflow-y: auto;"
                                            :disabled="!editMode"></textarea>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div class="row" style="padding: 1rem; height: 10%;">
                            <div class="row d-flex justify-content-center">
                                <button type="button" class="btn btn-primary btn-lg mr-5" :disabled="!editMode"
                                    @click="saveData()">저장</button>
                                <button type="button" class="btn btn-secondary btn-lg"
                                    @click="hideSysDetail()">닫기</button>
                            </div>
                            <div class="col-md-12 p-0 d-flex align-items-end justify-content-between"
                                style="height: 100%;">
                                <span>{{ `최초 등록일: ${detailInfo.regDate}` }}</span>
                                <span>{{ `최종 변경일: ${detailInfo.modDate}` }}</span>
                                <span>{{ `담당자: ${detailInfo.regWorker}` }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import headerBox from "@views/component/headerBox/analysis/Header.vue";
import backEndApi from "@src/api/backEndApi";

export default {
    components: {
        headerBox,
    },
    props: {},
    data() {
        return {
            columns: [ // 테이블 column
                {
                    label: this.$t("공통 코드"),
                    field: "rowTitle",
                    width: "250px",
                    type: "text",
                    thClass: "text-center text-nowrap ",
                    tdClass: "text-center text-nowrap",
                    filterOptions: {
                        enabled: true,
                        placeholder: "코드 또는 코드명으로 검색...",
                        // filterFn: this.filterFn2,
                    },                    
                },
                {
                    label: this.$t("카테고리"),
                    field: "codeCategory",
                    width: "150px",
                    type: "text",
                    thClass: "text-center text-nowrap ",
                    tdClass: "text-center text-nowrap",
                    filterOptions: {
                        enabled: true,
                        placeholder: "선택",
                        filterDropdownItems: [],
                        // filterDropdownItems: ['Cost', 'Point',],
                    },
                },
                {
                    label: this.$t("설명"),
                    field: "desc",
                    type: "text",
                    sortable: false,
                    thClass: "text-center text-nowrap",
                    tdClass: "text-center text-nowrap",
                    filterOptions: {
                        enabled: true,
                        placeholder: "검색...",
                    },                    
                },
                {
                    label: this.$t("현장운영코드"),
                    field: "oprtnCodeYn",
                    width: "100px",
                    type: "text",
                    sortable: false,
                    thClass: "text-center text-nowrap",
                    tdClass: "text-center text-nowrap",
                    filterOptions: {
                        enabled: true,
                        placeholder: "선택...",
                        filterDropdownItems: [
                            { value: "Y", text: "지원" },
                            { value: "N", text: "미지원" },
                        ],
                    },
                },
                {
                    label: this.$t("사용여부"),
                    field: "enabled",
                    width: "100px",
                    type: "text",
                    sortable: false,
                    thClass: "text-center text-nowrap",
                    tdClass: "text-center text-nowrap",
                    filterOptions: {
                        enabled: true,
                        placeholder: "선택...",
                        filterDropdownItems: [
                            { value: "Y", text: "사용" },
                            { value: "N", text: "미사용" },
                        ],
                    },
                    filterFn: (value, filter) => {
                        if (!filter) return true;
                        return value === filter;
                    },                    
                },
            ],

            rows: null, // 목록 데이터 담는 변수
            showAdd: false, // 목록 추가 창 표출 유무
            showDetail: false, // 디테일 정보 표출 유무
            editMode: false, // 디테일 정보 편집 유무

            categories: [],

            detailInfo: null,
            checkedRow: null,
            selectRowIdx: null,

            commonCode: {
                commonCode: "",
                codeName: "",
                oprtnCodeYn: "",
                enabled: "",
                desc: ""
            },
        }
    },
    computed: { },
    async created() {
        await this.getDataList();
        this.$refs.listTable.expandAll();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateMaxTableHeight);
    },
    mounted() {
        this.calculateMaxTableHeight();
        window.addEventListener('resize', this.calculateMaxTableHeight);

        this.$refs.listTable.expandAll();      
    },
    methods: {
        // filterFn2: (value, filter) => {
        //     console.log('################ filter check value : ', value)
        //     if (!filter) return true;
        //     return value === filter;
        // },   
        calculateMaxTableHeight() {
            if (this.$refs && this.$refs.parentDiv) {
                this.maxTableHeight = Math.floor(this.$refs.parentDiv.clientHeight * 0.987) + 'px';
                console.log('calc maxTableHeight : ', this.maxTableHeight);
            }
        },
        rowStyleClassFn(row) {
            if (row.originalIndex == this.selectRowIdx) {
                return 'selectedRow';
            }
            return 'VGT-row';
        },
        onSelectedRowChange(selected) {
            this.checkedRow = null;
            this.checkedRow = selected.selectedRows
        },
        baseInit() {
            this.commonCode = {
                commonCode: "",
                codeName: "",
                enabled: "",
                desc: ""
            }
        },
        // 중복을 제거한 B 속성 값들을 포함한 배열을 반환하는 함수
        extractUniqueValues(arr, prop) {
            if (arr === undefined || arr === null) return [];

            const uniqueValues = new Set(); // 중복을 제거하기 위한 Set 객체 생성

            // 주어진 배열의 각 객체를 순회하면서 B 속성 값만을 추출하여 Set에 추가
            arr.forEach(obj => {
                if (obj && obj[prop] !== undefined && obj[prop] !== null) {
                    uniqueValues.add(obj[prop]);
                }
            });

            // Set 객체를 배열로 변환하여 반환 (중복이 제거된 상태)
            return Array.from(uniqueValues);
        },
        setFilterDropdownItems() {
            const filteredFields = ['codeCategory'];

            if (!this.rows.length) return [];
            filteredFields.forEach(fieldName => {                
                const header = this.columns.find(header => header.field === fieldName)
                if (header) {
                    header.filterOptions.filterDropdownItems = this.categories;
                }
            })
        },
        async getDataList() {
            try {
                let result = await backEndApi.commonCode.getCommonCodeList()
                if (result.data) {
                    this.rows = result.data;
                    await this.rows.map(item => {
                        item.rowTitle = item.commonCode + ' ' + item.codeName;
                        item.oprnCodeYnToggle = item.oprtnCodeYn === "Y" ? true : false
                        item.enabledToggle = item.enabled === "Y" ? true : false
                        return item;
                    })

                    this.categories = this.extractUniqueValues(this.rows, 'codeCategory');

                    const newData = this.categories.map(category => {
                        const row = {
                            mode: 'span',
                            label: category,
                            html: false,
                            children: this.rows.filter(item => item.codeCategory === category ),
                        };

                        return row;
                    });

                    this.rows = newData;
                    
                    console.log('group data : ', this.rows);

                    this.setFilterDropdownItems();
                }
            } catch (e) {
                console.log(e);
            }
        },
        async saveData() {
            if(!this.editMode) {
                try {
                    let result = await backEndApi.commonCode.saveCommonCode(this.commonCode);
                    if(result.data) {
                        await this.alertNoti("저장하였습니다.");
                        this.showAdd = false;
                        await this.baseInit();
                        await this.getDataList();
                    }
                } catch (e) {
                    this.alertNoti("저장에 실패하였습니다.");
                    console.error(e);
                }
            } else {
                try {
                    let result = await backEndApi.commonCode.saveCommonCode(this.detailInfo);
                    if(result.data) {
                        await this.alertNoti("저장하였습니다.");
                        await this.getDataList();
                        this.showDetail = false;
                        this.editMode = false;
                        this.detailInfo = null;
                    }
                } catch (e) {
                    this.alertNoti("저장에 실패하였습니다.");
                    console.error(e);
                }
            }
        },
        async setOprtnCodeYn(oprtnCodeYnToggle, commonCode) {
            this.showDetail = false;

            const data = {
                "commonCode": commonCode,
                "oprtnCodeYn": oprtnCodeYnToggle ? "Y" : "N",
            }
            try {
                let result = await backEndApi.commonCode.setOprtnCodeYn(data);
                if (result.data) {
                    await this.alertNoti("변경하였습니다.");
                    await this.getDataList();
                }
            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },
        async setEnabled(enabledToggle, commonCode) {
            this.showDetail = false;
            
            const data = {
                "commonCode": commonCode,
                "enabled": enabledToggle? "Y" : "N",
            }
            try {
                let result = await backEndApi.commonCode.setEnabled(data);
                if(result.data) {
                    await this.alertNoti("상태를 변경하였습니다.");
                    await this.getDataList();
                } 
            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }            
        },
        async setBulkEnabled(enabledToggle) {
            let data;
            try {
                if(this.checkedRow !== null) {
                    this.checkedRow.forEach(async(item) => {
                        data = {
                            "commonCode": item.commonCode,
                            "enabled": enabledToggle? "Y" : "N",
                        }
                        await backEndApi.commonCode.setEnabled(data);
                    })
                    await this.alertNoti("상태를 변경하였습니다.");
                    await this.getDataList();
                    this.checkedRow = null;
                } else {
                    this.alertNoti("먼저 목록을 선택해주세요.")
                }
            } catch (e) {
                console.error(e)
                this.alertNoti("상태 변경에 실패하였습니다.");
            }
        },
        async deleteData() {
            try {
                const confirm = await this.alertConfirmWarning("선택한 목록을 삭제하시겠습니까?");
                if(!confirm.value) return;

                if(this.checkedRow !== null) {
                    this.checkedRow.forEach(async(item) => await backEndApi.commonCode.deleteCommonCode(item.commonCode));
                    await this.alertNoti("선택한 목록을 삭제하였습니다.")
                    await this.getDataList();
                    this.checkedRow = null;
                } else {
                    this.alertNoti("삭제할 목록을 선택해주세요.")
                }
            } catch (e) {
                this.alertNoti("삭제에 실패하였습니다.");
                console.error(e)
            }
        },
        async getData(params) {
            this.selectRowIdx = params.row.originalIndex;
            this.showAdd = false;
            if (this.detailInfo !== params.row) {
                this.showDetail = true;
                this.editMode = false;
                this.detailInfo = { ...params.row };
                // this.detailInfo.regDate = moment(this.detailInfo.regDate).format("YYYY-MM-DD")
                // this.detailInfo.modDate = moment(this.detailInfo.modDate).format("YYYY-MM-DD")                
                // this.detailInfo = {...this.detailInfo};
            } else { 
                this.showDetail = false;
                this.detailInfo = null;
                this.detailInfo = null;
            }
        },
        showAddSysType() {
            this.showDetail = false;
            this.showAdd = true;
        },
        async hideAddSysType() {
            let select = document.getElementsByClassName("selectedRow")
            if (select[0].classList.contains("selectedRow")) {
                select[0].classList.remove("selectedRow");
            }
            if(this.commonCode.commonCode || this.commonCode.codeName || this.commonCode.enabled || this.commonCode.desc) {
                const result = await this.alertConfirmWarning("입력 내용을 무시하고 종료하시겠습니까?");
                if (!result.value) return;
                else {
                    this.showAdd = false;
                    this.baseInit();
                }
            } else {
                this.showAdd = false;
            }
        },
        async hideSysDetail() {
            if(this.detailInfo.codeName !== this.detailInfo.codeName || this.detailInfo.enabled !== this.detailInfo.enabled || this.detailInfo.desc !== this.detailInfo.desc) {
                const confirm = await this.alertConfirmWarning("입력 내용을 무시하고 종료하시겠습니까?");
                if (!confirm.value) return;
                else {
                    this.showDetail = false;
                    this.editMode = false;
                    this.detailInfo = null;
                    this.detailInfo = null;
                }
            } else {
                this.showDetail = false;
                this.editMode = false;
                this.detailInfo = null;
                this.detailInfo = null;
            }
        }
    },
}

</script>

<style>
.custom-switch.custom-switch-md .custom-control-label::before {
    height: 1.5rem;
    width: calc(2rem + 0.75rem);
    border-radius: 3rem;
}

.custom-switch.custom-switch-md .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(calc(1.5rem - 0.25rem));
}

table.vgt-table td {
    vertical-align: middle;
}

.VGT-row:hover {
    background-color: #f2f3f4;
}

.selectedRow {
    background-color: #f2f3f4;
}
</style>